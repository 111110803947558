import { InputContainer } from '@component/utils/form/input'
import {
  createLogSelector,
  getCreateTestSelector,
  getFilterSelector,
  getSiteDetailSelector,
} from '@service/selectors/site'
import { Card, CardBody } from '@style/components/card'
import Form, { FormCheck, FormGroup, FormInput, FormError, FormSelect } from '@util/form'
import moment from 'moment/moment'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'
import dynamic from 'next/dynamic'
import { Button } from '@style/components/button'
import 'react-quill/dist/quill.snow.css'
import { closePopup } from '@action/menu'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { createLog, getSiteFilterList } from '@action/site'
import styled from 'styled-components'

const ReactQuill = dynamic(() => import('react-quill'), {
  ssr: false,
})

const CardContainer = styled.div`
  .ql-editor {
    min-height: 150px;
  }
`

const RegisterChange = ({ close }) => {
  const [formError] = useSelector(createLogSelector)
  const [siteDetail] = useSelector(getSiteDetailSelector)
  const [filters] = useSelector(getFilterSelector('seoTestActionChange'))
  const Dispatch = useDispatch()
  const { query } = useRouter()
  const initialValues = useMemo(() => {
    return {
      title: '',
      url: siteDetail?.url,
      date_change: moment().format('DD/MM/YYYY'),
      description: '',
      action_changes: [],
    }
  }, [])
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      title: Yup.string().required(),
      url: Yup.string().required(),
    })
  }, [])
  const handleSubmit = useCallback(
    form => {
      const subChanges = Object.entries(form.action_changes).map(([l, r]) => ({
        slug: l,
        value: r,
      }))

      for (const fit of filters) {
        if (!subChanges.find(sb => sb.slug == fit.value)) {
          subChanges.push({
            slug: fit.value,
            value: false,
          })
        }
      }
      const formData = {
        title: form.title,
        url: form.url,
        date_change: moment(form.date_change, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        description: form.description,
        action_changes: subChanges,
      }

      Dispatch(createLog(query.id, formData, close))
    },
    [filters]
  )

  useEffect(() => {
    Dispatch(getSiteFilterList())
  }, [])

  return (
    <CardContainer className="w-[800px] max-h-[calc(100vh-100px)] ">
      {/* {console.log(asPath)} */}
      <Card className="px-4">
        <CardBody>
          <h3 className="text-[28px] mb-5">Register Change</h3>
          <Form enableReinitialize validation={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
            {form => (
              <>
                <FormGroup>
                  <div className="flex flex-col mb-5 leading-5">
                    <span className="font-normal text-sm mb-[6px]">Title</span>
                    <FormInput
                      id="title"
                      name="title"
                      placeholder="Add the title for your change"
                      form={form}
                      withError
                      errors={formError}
                    />
                  </div>
                  <div className="flex flex-col mb-5 leading-5">
                    <span className="font-normal text-sm mb-[6px]">URL</span>
                    <FormInput id="url" name="url" placeholder="https://" form={form} withError />
                  </div>
                  <div className="flex flex-col mb-5 leading-5">
                    <span className="font-normal text-sm mb-[6px]">Date Change Was Made</span>
                    <InputContainer>
                      <Flatpickr
                        options={{
                          maxDate: 'today',
                          dateFormat: 'd/m/Y',
                        }}
                        value={form.values.date_change}
                        onChange={value => form.setValues({ ...form.values, date_change: value[0] })}
                        render={({ value }, ref) => (
                          <>
                            <input type="text" name="date_change" id="date_change" ref={ref} placeholder="DD/MM/YYYY" />
                          </>
                        )}
                      />
                      {(form.errors?.date_change || formError?.date_change) && (
                        <FormError className="error-input">
                          {_.upperFirst(
                            (form.errors.date_change || formError?.date_change).replaceAll('_', ' ')?.replace('md ', '')
                          )}
                        </FormError>
                      )}
                    </InputContainer>
                  </div>
                  <div className="flex flex-col mb-5 leading-5">
                    <span className="font-normal text-sm mb-[6px]">Description </span>
                    <ReactQuill
                      theme="snow"
                      value={form.values.description}
                      onChange={val => form.setValues({ ...form.values, description: val })}
                    />
                    {(form.errors?.description || formError?.description) && (
                      <FormError className="error-input">
                        {_.upperFirst(
                          (form.errors.description || formError?.description).replaceAll('_', ' ')?.replace('md ', '')
                        )}
                      </FormError>
                    )}
                  </div>
                  <h5 className="font-extrabold text-lg">What Changed</h5>
                  <div className="pb-5 mt-3">
                    <div className="grid grid-cols-2 mt-2">
                      {filters.map((fr, idx) => (
                        <div className="col-1 mb-2" key={idx}>
                          {/* {console.log(fr.value, form.values.action_changes.find(t => t.name == fr.value)?.value)} */}
                          <FormCheck
                            id={fr.value}
                            name={`action_changes`}
                            label={fr.label}
                            form={form}
                            checked={form.values.action_changes[fr.value]}
                            onChange={e =>
                              form.setValues({
                                ...form.values,
                                action_changes: {
                                  ...form.values.action_changes,
                                  [fr.value]: e.target.checked,
                                },
                              })
                            }
                            withError
                          ></FormCheck>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <Button type="submit" btnColor="white" style={{ fontWeight: 'bold' }}>
                      Register
                    </Button>
                    <Button
                      btnColor="dark"
                      btnBorder="grey7"
                      btnBackground="white"
                      onClick={() => Dispatch(closePopup('register-change'))}
                    >
                      Cancel
                    </Button>
                  </div>
                </FormGroup>
              </>
            )}
          </Form>
        </CardBody>
      </Card>
    </CardContainer>
  )
}

export default RegisterChange
