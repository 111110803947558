import { getAllSiteSelector, getSiteDetailSelector } from '@service/selectors/site'
import Image from 'next/image'
import { useDispatch, useSelector } from 'react-redux'
import Router, { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getSiteList, setSiteDetail } from '@action/site'
import classNames from 'classnames'
import Link from 'next/link'
import { getRoute } from '@hook/router'

const ChooseSite = () => {
  const { query, pathname } = useRouter()
  const elementRef = useRef()
  const triggerRef = useRef()
  const [siteDetail] = useSelector(getSiteDetailSelector)
  const [sites] = useSelector(getAllSiteSelector)
  const isMenuExpand = useSelector(state => state.app?.menu?.expand)
  const Dispatch = useDispatch()
  const [showDropdown, setShowDropdown] = useState(false)
  const [search, setSearch] = useState('')
  const router = useRouter()
  const setActiveSite = useCallback(siteId => {
    let url = pathname.split('[trackingId]')[0]
    if (url !== '/') {
      url = url.replace('[id]', siteId)
      for (const key in query) {
        url = url.replace(`[${key}]`, query[key])
      }
    } else {
      return Router.push(getRoute('site.detail', { id: siteId }))
    }

    window.location.href = url
    // close()
  }, [])

  useEffect(() => {
    const handleOutsideClick = e => {
      if (!elementRef?.current?.contains(e.target) && !triggerRef?.current?.contains(e.target)) {
        setShowDropdown(false)
      }
    }
    document.addEventListener('click', handleOutsideClick)
    return () => document.removeEventListener('click', handleOutsideClick)
  }, [])

  const handleSelectSite = useCallback(siteId => {
    setActiveSite(siteId)
    Dispatch(setSiteDetail(siteId))
  })

  useEffect(() => {
    Dispatch(getSiteList(search))
  }, [Dispatch, search])

  return (
    <>
      <div
        ref={triggerRef}
        onClick={() => setShowDropdown(!showDropdown)}
        className="cursor-pointer text-left bg-body border rounded-[15px] border-grey-100 px-[15px] py-[10px] mx-[10px] mt-[10px]"
      >
        {isMenuExpand && (
          <div className="bg-[rgba(0, 0, 0, 0.03)] flex rounded-full">
            <Image
              className="object-contain"
              alt="site logo"
              src={siteDetail?.logoPath || `/assets/images/logo.svg`}
              width="25px"
              height="25px"
            />
          </div>
        )}
        {!isMenuExpand && (
          <div className="relative">
            <p className="uppercase text-[#3F434A] text-sm">Project</p>
            <div className="flex w-full justify-between">
              <p
                className={classNames(
                  { 'font-bold text-[#000]': siteDetail.name },
                  'mr-2 font-medium text-gray-500 overflow-hidden text-[14px] overflow-ellipsis'
                )}
              >
                {siteDetail.name || '-- Select website --'}
              </p>
            </div>
            <i className="bx bx-expand-vertical absolute right-0 top-1/2 -translate-y-1/2"></i>
          </div>
        )}
      </div>
      {showDropdown && (
        <div
          ref={elementRef}
          className={classNames(
            { 'absolute left-full top-0 z-10': isMenuExpand },
            'absolute w-[218px] text-[14px] index z-10 text-left bg-white border rounded-[15px] border-gray-200 pt-[10px] mx-[10px] mt-[10px] shadow-lg'
          )}
        >
          <label className={`w-full flex items-center rounded-sm overflow-hidden pb-3 border-b mb-1  px-[15px]`}>
            <input
              type="text"
              className={` w-full rounded-md text-[${props =>
                props.theme.colors
                  .grey5}] text-md outline-none border focus:border-2 focus:border-[#304FFD] border-[${props =>
                props.theme.colors.grey7}] bg-transparent px-1.5 py-1`}
              style={{ flex: '0 0 calc(100%-25px)', maxWidth: 'calc(100%-25px)' }}
              onChange={e => setSearch(e.target.value)}
              value={search}
              placeholder="Search site ..."
            />
          </label>
          <div className="max-h-[150px] overflow-auto">
            {sites.map(site => (
              <div
                onClick={() => handleSelectSite(site.id)}
                className={classNames({ 'bg-gray-200': site.id == query.id }, 'hover:bg-gray-100 cursor-pointer')}
                key={site.id}
              >
                <p className=" px-[15px] text-gray-500 py-1">{site.name}</p>
              </div>
            ))}
          </div>
          <div
            onClick={() => router.push('/')}
            className="border-y flex px-[15px] py-2 items-center cursor-pointer justify-between text-gray-600 font-PlusJakartaSansBold  hover:bg-gray-50"
          >
            <p>See all projects</p>
            <i className="bx bx-chevron-right text-[24px]"></i>
          </div>
          <div className="flex px-[15px] py-2 items-center cursor-pointer justify-between  text-gray-600 font-PlusJakartaSansBold  hover:bg-gray-100">
            <Link href={getRoute('site.all', {}, { create: true })}>
              <p>+ New Project</p>
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default ChooseSite
